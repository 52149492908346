import moment from 'moment';
import { Modal } from 'antd';

export const config = {
    env: 'local',
    dbpref: 'fyv_',
    version: require('../../package.json').version,
    apiToken: '',
    headers: {},
    headersFile: {},
    dateFormat: 'MMM DD, YYYY HH:mm A',
};

export const app = {
    version: require('../../package.json').version,
}

export const initialize = () => {
    if (window.location.host.match(/localhost/i)) {
        config.env = 'local';
    } else if (window.location.host === 'qa-app.fayavotes.com') {
        config.env = 'dev';
    } else {
        config.env = 'live';
    }
}

export const dates = {
    yr: moment().format('YYYY'),
}


// ::: storage
export const setStorage = (key, value) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, value);
    }
}
export const getStorage = (key) => {
    const value = localStorage.getItem(config.dbpref + key);
    return value || '';
}
export const setStorageJson = (key, value) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, JSON.stringify(value));
    }
}
export const getStorageJson = (key) => {
    if (key) {
        const value = localStorage.getItem(config.dbpref + key);
        return JSON.parse(value) || '';
    }
}
export const delStorage = (key) => {
    if (key) {
        localStorage.removeItem(config.dbpref + key);
    }
}

export const mergeObj = (obj, src) => {
    for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
}
export const getFileExtension = (filename) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext === null ? '' : ext[1];
}

// Spinners
export const fspinner = <div style={{ textAlign: 'center', color: '#999', lineHeight: 320 + 'px', width: 100 + '%' }}><i className="fa fa-spin fa-circle-o-notch fa-5x"></i></div>;
export const fspinner_sm = <div style={{ textAlign: 'center', color: '#999', lineHeight: 120 + 'px', width: 100 + '%' }}><i className="fa fa-spin fa-circle-o-notch fa-3x"></i></div>;
export const fspinner_xs = <i className="fa fa-spin fa-circle-o-notch"></i>;

export const redirect = (to) => {
    window.location = to;
}

export const openNewTab = (to) => {
    window.open(to, '_blank');
}

export const generateOptions = (length, step = 1) => {
    const arr = [];
    for (let value = 0; value < length; value += step) {
        arr.push(value);
    }
    return arr;
};

export const numberFormat = (number, minimumFractionDigits = 0) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits }).format(number);
}

export const loading = (content) => {
    return new Promise((resolve) => {
        content = <div dangerouslySetInnerHTML={{ __html: `<i class="fa fa-spin fa-spinner"></i> <span>${content}</span>` }} />
        const loading = Modal.info({
            icon: null,
            title: null,
            centered: true,
            content,
            width: '250px',
            cancelText: <div />,
        });
        resolve(loading);
    });
}

export const alert = (title, content, props, onOK) => {
    Modal.confirm({
        icon: null,
        title,
        centered: true,
        content,
        okText: 'Okay!',
        width: '300px',
        cancelTextProps: { style: { display: 'none' } },
        ...props,
        // onOk: (close) => {
        //     close();
        //     onOK && onOK();
        // }
    });
}



export const serviceFee = (a = {}) => {
    return +a.revenue * (+a.preferences.norm_vote_fee / 100);
}

export const revenue = (a = {}) => {
    return +a.revenue - serviceFee(a);
}

export const versionBreak = (v) => {
    return v ? Number(v.split('.').join('')) : 0;
}


export const ucFirst = (str) => {
    return str ? str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1) : '';
}

export const ucWords = (str) => {
    return str ? str.split(' ').map(str => { return ucFirst(str); }).join(' ') : '';
}

export const chunk = (inputArray, chunkSize) => {
    return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);
}

export const scrollTop = () => {
    if (window.location.hostname !== 'localhost') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

export const requiredFieldRule = [{ required: true, message: 'This fild is required!' },];

export const copyObject = (object) => JSON.parse(JSON.stringify(object));

export const encode = (str) => {
    var encoded = '';
    str = JSON.stringify(str);
    str = Buffer.from(str).toString('base64');
    str = Buffer.from(str).toString('base64');
    for (let i = 0; i < str.length; i++) {
        var a = str.charCodeAt(i);
        var b = a ^ 10;
        encoded = encoded + String.fromCharCode(b);
    }
    encoded = Buffer.from(encoded).toString('base64');
    return encoded;
}

export const checkDates = (start, end) => {
    if (start && end) {
        return moment().isBetween(start, end);
    }
    return false;
}

export const useDisableNumberInputScroll = () => {
    document.addEventListener('wheel', function (event) {
        if (document.activeElement.type === 'number') {
            document.activeElement.blur();
        }
    });
    // // Use the useEffect hook to manage side effects
    // React.useEffect(() => {
    //     // Define a function to prevent the default scroll behavior
    //     const handleWheel = (e) => {
    //         e.preventDefault();
    //     };

    //     // Find all number input elements in the document
    //     const numberInputs = document.querySelectorAll('input[type="number"]');

    //     // Attach the handleWheel function as an event listener to each number input
    //     numberInputs.forEach((input) => {
    //         input.addEventListener('wheel', handleWheel, { passive: false });
    //     });

    //     // Clean up by removing the event listeners when the component unmounts
    //     return () => {
    //         numberInputs.forEach((input) => {
    //             input.removeEventListener('wheel', handleWheel);
    //         });
    //     };
    // }, []); // The empty dependency array ensures that this effect runs once, like componentDidMount
};